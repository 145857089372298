import axios from 'axios';
import { GET_ITEMS, ADD_ITEMS, DELETE_ITEM, ITEMS_LOADING } from "./types";


export const getItems = () => dispatch => { // dispatch from redux thunk
    dispatch(setItemsLoading);
    axios.get('https://list-app-t61y.onrender.com/api/items')
        .then(res =>
            dispatch({
                type: GET_ITEMS,
                payload: res.data
            })
        )
}


export const addItem = (item) => dispatch => {
    axios.post('https://list-app-t61y.onrender.com/api/items', item)
        .then(res =>
            dispatch({
                type: ADD_ITEMS,
                payload: res.data
            })
        )
}

export const deleteItem = (id) => dispatch => {
    axios.delete(`https://list-app-t61y.onrender.com/api/items/${id}`)
        .then(res =>
            dispatch({
                type: DELETE_ITEM,
                payload: id

            })
        )
}


export const setItemsLoading = () => {
    return {
        type: ITEMS_LOADING
    }
}


